import { DateTime } from 'luxon';

import { EntityConstructor, SpecialHourNotificationAction } from '@malou-io/package-utils';

import { CalendarEvent } from ':shared/models/calendar-event';

import { Notification } from './notification.model';

export type SpecialHourNotificationEventData = Pick<CalendarEvent, 'id' | 'emoji' | 'startDate' | 'name'> & {
    action: SpecialHourNotificationAction;
};

export interface SpecialHourNotificationData {
    restaurantIds: string[];
    event: SpecialHourNotificationEventData;
}

type SpecialHourNotificationProps = EntityConstructor<SpecialHourNotification>;

export class SpecialHourNotification extends Notification {
    data: SpecialHourNotificationData;

    constructor(props: SpecialHourNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<SpecialHourNotificationProps>): SpecialHourNotification {
        return new SpecialHourNotification({ ...this, ...props });
    }

    getFormattedEventDate = (): string => DateTime.fromJSDate(new Date(this.data.event.startDate)).toFormat('dd MMMM');

    getNotificationEmoji = (): string => this.data.event.emoji ?? '🎉';

    getNotificationName = (): string => this.data.event.name[localStorage.getItem('lang') ?? 'fr'];
}
