import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, SpecialHourNotificationAction } from '@malou-io/package-utils';

import { SpecialHourNotification } from ':core/components/notification-center/models/special-hour-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-special-hour-notification-item',
    templateUrl: './special-hour-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, MatButtonModule],
})
export class SpecialHourNotificationComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    private readonly _notificationsService = inject(NotificationService);

    readonly notification = computed(() => this.initialNotification() as SpecialHourNotification);
    readonly isUserActionCompleted = computed(() => this.notification().data.event.action !== SpecialHourNotificationAction.NOTHING);
    readonly specialHourNotificationActionText = computed(() => {
        switch (this.notification().data.event.action) {
            case SpecialHourNotificationAction.CLOSED:
                return 'notification-center.special-hour-notification-item.button.closed';
            case SpecialHourNotificationAction.NO_CHANGE:
                return 'notification-center.special-hour-notification-item.button.open';
            default:
                return '';
        }
    });
    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);
    readonly getNotificationEmoji = computed(() => this.notification().data.event.emoji);

    onNotificationClick(): void {
        this.markAsRead();
        this.notificationCenterContext.close();
    }

    redirectToInformationPage(): void {
        this._router.navigate([`/restaurants/${this.notification().data.restaurantIds[0]}/seo/informations`]);
    }

    onOpenClick(): void {
        // TODO: Sync with Mazigh
        throw new Error('Method not implemented. will be done later!');
        this._notificationsService
            .trackDiagnosticAction$({
                eventName: this.notification().data.event.name.fr ?? '',
                heapEventName: HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_OPEN_ACTION,
                notificationId: this.notification().id,
            })
            .subscribe();
    }

    onClosedClick(): void {
        // TODO: Sync with Mazigh
        throw new Error('Method not implemented. will be done later!');
        this._notificationsService
            .trackDiagnosticAction$({
                eventName: this.notification().data.event.name.fr ?? '',
                heapEventName: HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSED_ACTION,
                notificationId: this.notification().id,
            })
            .subscribe();
    }
}
