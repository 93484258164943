import { Day, PostPublicationStatus } from '@malou-io/package-utils';

import { MalouPeriod, TimePeriod } from ':shared/models';
import { PhoneCode } from ':shared/models/phone-code';

export { countries } from './countries';

export const periodOptions = [
    { label: 'date_overview.last_seven_days', key: MalouPeriod.LAST_SEVEN_DAYS },
    { label: 'date_overview.last_thirty_days', key: MalouPeriod.LAST_THIRTY_DAYS },
    { label: 'date_overview.last_six_months', key: MalouPeriod.LAST_SIX_MONTHS },
    { label: 'date_overview.last_twelve_months', key: MalouPeriod.LAST_TWELVE_MONTHS },
];

export const notionLinks = Object.freeze({
    GENERAL: 'https://welcomehomemalou.notion.site/Foire-aux-questions-Malou-257b1ca546b747efaca1b63345da9603',
    RECONNECT_SOCIAL_NETWORKS:
        'https://welcomehomemalou.notion.site/Pourquoi-dois-je-reconnecter-mes-r-seaux-sociaux-784583aa4f1242dba71ab1ed852ce5bc',
    IMPORT_CLIENTS: 'https://welcomehomemalou.notion.site/Comment-t-l-charger-mes-donn-es-clients-9aaa6102625647e0a86f8564fb7f6496',
    BANNED_HASHTAGS:
        'https://welcomehomemalou.notion.site/Quels-sont-les-hashtags-bannis-par-Instagram-et-Facebook-83121e78b6aa4c5da32f2805ba2097fc',
    FB_PAGE:
        'https://welcomehomemalou.notion.site/Comment-changer-le-type-de-page-d-tablissement-Facebook-97c561ddabfb4a83b2eafcc727df2f44',
    AUTHORIZE_MESSAGES: 'https://welcomehomemalou.notion.site/Autoriser-l-acc-s-aux-messages-409ed4e746ee4641a4cdfa707357d04e',
    INFORMATIONS: 'https://welcomehomemalou.notion.site/Comment-mettre-jour-ses-informations-0c35c6217e764d769b1c8a6671dc8a35',
});

export const thirdPartyLinks = Object.freeze({
    REFERRAL_LINK: 'https://content.malou.io/fr/referral?utm_campaign=MalouApp',
});

export const commentStatus = Object.freeze({
    POSTED: {
        key: 'posted',
        fr: 'Posté',
        en: 'Posted',
    },
    PENDING: {
        key: 'pending',
        fr: 'En attente',
        en: 'Pending',
    },
    MANUALLY_POSTED: {
        key: 'manually_posted',
        fr: 'Réponse postée manuellement',
        en: 'Manually posted',
    },
});

export const DescriptionsSizes = Object.freeze({
    LONG: {
        key: 'long',
        maxLength: 600,
    },
    SHORT: {
        key: 'short',
        maxLength: 100,
    },
});

export const days = Object.freeze({
    [Day.MONDAY]: {
        digit: 1,
        fr: 'lundi',
        en: 'Monday',
        es: 'Lunes',
        it: 'Lunedì',
    },
    [Day.TUESDAY]: {
        digit: 2,
        fr: 'mardi',
        en: 'Tuesday',
        es: 'Martes',
        it: 'Martedì',
    },
    [Day.WEDNESDAY]: {
        digit: 3,
        fr: 'mercredi',
        en: 'Wednesday',
        es: 'Miércoles',
        it: 'Mercoledì',
    },
    [Day.THURSDAY]: {
        digit: 4,
        fr: 'jeudi',
        en: 'Thursday',
        es: 'Jueves',
        it: 'Giovedì',
    },
    [Day.FRIDAY]: {
        digit: 5,
        fr: 'vendredi',
        en: 'Friday',
        es: 'Viernes',
        it: 'Venerdì',
    },
    [Day.SATURDAY]: {
        digit: 6,
        fr: 'samedi',
        en: 'Saturday',
        es: 'Sábado',
        it: 'Sabato',
    },
    [Day.SUNDAY]: {
        digit: 7,
        fr: 'dimanche',
        en: 'Sunday',
        es: 'Domingo',
        it: 'Domenica',
    },
});

export enum ImgFormat {
    PNG = 'png',
    JPEG = 'jpeg',
    JPG = 'jpg',
}

export enum PhotoCategory {
    PROFILE = 'profile',
    COVER = 'cover',
    ADDITIONAL = 'additional',
}

export const downloadMedias = Object.freeze({
    LIMIT: 3,
});

export const dateFormat = Object.freeze({
    fr: 'DD-MM-yyyy',
});

export const PHONE_CODES: readonly PhoneCode[] = Object.freeze([
    { countryCode: 'FR', code: 33, text: '(+33) France' },
    { countryCode: 'ES', code: 34, text: '(+34) Spain' },
    { countryCode: 'GB', code: 44, text: '(+44) UK' },
    { countryCode: 'US', code: 1, text: '(+1) USA' },
    { countryCode: 'DZ', code: 213, text: '(+213) Algeria' },
    { countryCode: 'AD', code: 376, text: '(+376) Andorra' },
    { countryCode: 'AO', code: 244, text: '(+244) Angola' },
    { countryCode: 'AI', code: 1264, text: '(+1264) Anguilla' },
    { countryCode: 'AG', code: 1268, text: '(+1268) Antigua & Barbuda' },
    { countryCode: 'AR', code: 54, text: '(+54) Argentina' },
    { countryCode: 'AM', code: 374, text: '(+374) Armenia' },
    { countryCode: 'AW', code: 297, text: '(+297) Aruba' },
    { countryCode: 'AU', code: 61, text: '(+61) Australia' },
    { countryCode: 'AT', code: 43, text: '(+43) Austria' },
    { countryCode: 'AZ', code: 994, text: '(+994) Azerbaijan' },
    { countryCode: 'BS', code: 1242, text: '(+1242) Bahamas' },
    { countryCode: 'BH', code: 973, text: '(+973) Bahrain' },
    { countryCode: 'BD', code: 880, text: '(+880) Bangladesh' },
    { countryCode: 'BB', code: 1246, text: '(+1246) Barbados' },
    { countryCode: 'BY', code: 375, text: '(+375) Belarus' },
    { countryCode: 'BE', code: 32, text: '(+32) Belgium' },
    { countryCode: 'BZ', code: 501, text: '(+501) Belize' },
    { countryCode: 'BJ', code: 229, text: '(+229) Benin' },
    { countryCode: 'BM', code: 1441, text: '(+1441) Bermuda' },
    { countryCode: 'BT', code: 975, text: '(+975) Bhutan' },
    { countryCode: 'BO', code: 591, text: '(+591) Bolivia' },
    { countryCode: 'BA', code: 387, text: '(+387) Bosnia Herzegovina' },
    { countryCode: 'BW', code: 267, text: '(+267) Botswana' },
    { countryCode: 'BR', code: 55, text: '(+55) Brazil' },
    { countryCode: 'BN', code: 673, text: '(+673) Brunei' },
    { countryCode: 'BG', code: 359, text: '(+359) Bulgaria' },
    { countryCode: 'BF', code: 226, text: '(+226) Burkina Faso' },
    { countryCode: 'BI', code: 257, text: '(+257) Burundi' },
    { countryCode: 'KH', code: 855, text: '(+855) Cambodia' },
    { countryCode: 'CM', code: 237, text: '(+237) Cameroon' },
    { countryCode: 'CA', code: 1, text: '(+1) Canada' },
    { countryCode: 'CV', code: 238, text: '(+238) Cape Verde Islands' },
    { countryCode: 'KY', code: 1345, text: '(+1345) Cayman Islands' },
    { countryCode: 'CF', code: 236, text: '(+236) Central African Republic' },
    { countryCode: 'CL', code: 56, text: '(+56) Chile' },
    { countryCode: 'CN', code: 86, text: '(+86) China' },
    { countryCode: 'CO', code: 57, text: '(+57) Colombia' },
    { countryCode: 'KM', code: 269, text: '(+269) Comoros' },
    { countryCode: 'CG', code: 242, text: '(+242) Congo' },
    { countryCode: 'CK', code: 682, text: '(+682) Cook Islands' },
    { countryCode: 'CR', code: 506, text: '(+506) Costa Rica' },
    { countryCode: 'HR', code: 385, text: '(+385) Croatia' },
    { countryCode: 'CU', code: 53, text: '(+53) Cuba' },
    { countryCode: 'CY', code: 90392, text: '(+90392) Cyprus North' },
    { countryCode: 'CY', code: 357, text: '(+357) Cyprus South' },
    { countryCode: 'CZ', code: 42, text: '(+42) Czech Republic' },
    { countryCode: 'DK', code: 45, text: '(+45) Denmark' },
    { countryCode: 'DJ', code: 253, text: '(+253) Djibouti' },
    { countryCode: 'DM', code: 1809, text: '(+1809) Dominica' },
    { countryCode: 'DO', code: 1809, text: '(+1809) Dominican Republic' },
    { countryCode: 'EC', code: 593, text: '(+593) Ecuador' },
    { countryCode: 'EG', code: 20, text: '(+20) Egypt' },
    { countryCode: 'SV', code: 503, text: '(+503) El Salvador' },
    { countryCode: 'GQ', code: 240, text: '(+240) Equatorial Guinea' },
    { countryCode: 'ER', code: 291, text: '(+291) Eritrea' },
    { countryCode: 'EE', code: 372, text: '(+372) Estonia' },
    { countryCode: 'ET', code: 251, text: '(+251) Ethiopia' },
    { countryCode: 'FK', code: 500, text: '(+500) Falkland Islands' },
    { countryCode: 'FO', code: 298, text: '(+298) Faroe Islands' },
    { countryCode: 'FJ', code: 679, text: '(+679) Fiji' },
    { countryCode: 'FI', code: 358, text: '(+358) Finland' },
    { countryCode: 'GF', code: 594, text: '(+594) French Guiana' },
    { countryCode: 'PF', code: 689, text: '(+689) French Polynesia' },
    { countryCode: 'GA', code: 241, text: '(+241) Gabon' },
    { countryCode: 'GM', code: 220, text: '(+220) Gambia' },
    { countryCode: 'GE', code: 7880, text: '(+7880) Georgia' },
    { countryCode: 'DE', code: 49, text: '(+49) Germany' },
    { countryCode: 'GH', code: 233, text: '(+233) Ghana' },
    { countryCode: 'GI', code: 350, text: '(+350) Gibraltar' },
    { countryCode: 'GR', code: 30, text: '(+30) Greece' },
    { countryCode: 'GL', code: 299, text: '(+299) Greenland' },
    { countryCode: 'GD', code: 1473, text: '(+1473) Grenada' },
    { countryCode: 'GP', code: 590, text: '(+590) Guadeloupe' },
    { countryCode: 'GU', code: 671, text: '(+671) Guam' },
    { countryCode: 'GT', code: 502, text: '(+502) Guatemala' },
    { countryCode: 'GN', code: 224, text: '(+224) Guinea' },
    { countryCode: 'GW', code: 245, text: '(+245) Guinea - Bissau' },
    { countryCode: 'GY', code: 592, text: '(+592) Guyana' },
    { countryCode: 'HT', code: 509, text: '(+509) Haiti' },
    { countryCode: 'HN', code: 504, text: '(+504) Honduras' },
    { countryCode: 'HK', code: 852, text: '(+852) Hong Kong' },
    { countryCode: 'HU', code: 36, text: '(+36) Hungary' },
    { countryCode: 'IS', code: 354, text: '(+354) Iceland' },
    { countryCode: 'IN', code: 91, text: '(+91) India' },
    { countryCode: 'ID', code: 62, text: '(+62) Indonesia' },
    { countryCode: 'IR', code: 98, text: '(+98) Iran' },
    { countryCode: 'IQ', code: 964, text: '(+964) Iraq' },
    { countryCode: 'IE', code: 353, text: '(+353) Ireland' },
    { countryCode: 'IL', code: 972, text: '(+972) Israel' },
    { countryCode: 'IT', code: 39, text: '(+39) Italy' },
    { countryCode: 'JM', code: 1876, text: '(+1876) Jamaica' },
    { countryCode: 'JP', code: 81, text: '(+81) Japan' },
    { countryCode: 'JO', code: 962, text: '(+962) Jordan' },
    { countryCode: 'KZ', code: 7, text: '(+7) Kazakhstan' },
    { countryCode: 'KE', code: 254, text: '(+254) Kenya' },
    { countryCode: 'KI', code: 686, text: '(+686) Kiribati' },
    { countryCode: 'KP', code: 850, text: '(+850) Korea North' },
    { countryCode: 'KR', code: 82, text: '(+82) Korea South' },
    { countryCode: 'KW', code: 965, text: '(+965) Kuwait' },
    { countryCode: 'KG', code: 996, text: '(+996) Kyrgyzstan' },
    { countryCode: 'LA', code: 856, text: '(+856) Laos' },
    { countryCode: 'LV', code: 371, text: '(+371) Latvia' },
    { countryCode: 'LB', code: 961, text: '(+961) Lebanon' },
    { countryCode: 'LS', code: 266, text: '(+266) Lesotho' },
    { countryCode: 'LR', code: 231, text: '(+231) Liberia' },
    { countryCode: 'LY', code: 218, text: '(+218) Libya' },
    { countryCode: 'LI', code: 417, text: '(+417) Liechtenstein' },
    { countryCode: 'LT', code: 370, text: '(+370) Lithuania' },
    { countryCode: 'LU', code: 352, text: '(+352) Luxembourg' },
    { countryCode: 'MO', code: 853, text: '(+853) Macao' },
    { countryCode: 'MK', code: 389, text: '(+389) Macedonia' },
    { countryCode: 'MG', code: 261, text: '(+261) Madagascar' },
    { countryCode: 'MW', code: 265, text: '(+265) Malawi' },
    { countryCode: 'MY', code: 60, text: '(+60) Malaysia' },
    { countryCode: 'MV', code: 960, text: '(+960) Maldives' },
    { countryCode: 'ML', code: 223, text: '(+223) Mali' },
    { countryCode: 'MT', code: 356, text: '(+356) Malta' },
    { countryCode: 'MH', code: 692, text: '(+692) Marshall Islands' },
    { countryCode: 'MQ', code: 596, text: '(+596) Martinique' },
    { countryCode: 'MR', code: 222, text: '(+222) Mauritania' },
    { countryCode: 'YT', code: 269, text: '(+269) Mayotte' },
    { countryCode: 'MX', code: 52, text: '(+52) Mexico' },
    { countryCode: 'FM', code: 691, text: '(+691) Micronesia' },
    { countryCode: 'MD', code: 373, text: '(+373) Moldova' },
    { countryCode: 'MC', code: 377, text: '(+377) Monaco' },
    { countryCode: 'MN', code: 976, text: '(+976) Mongolia' },
    { countryCode: 'MS', code: 1664, text: '(+1664) Montserrat' },
    { countryCode: 'MA', code: 212, text: '(+212) Morocco' },
    { countryCode: 'MZ', code: 258, text: '(+258) Mozambique' },
    { countryCode: 'MN', code: 95, text: '(+95) Myanmar' },
    { countryCode: 'NA', code: 264, text: '(+264) Namibia' },
    { countryCode: 'NR', code: 674, text: '(+674) Nauru' },
    { countryCode: 'NP', code: 977, text: '(+977) Nepal' },
    { countryCode: 'NL', code: 31, text: '(+31) Netherlands' },
    { countryCode: 'NC', code: 687, text: '(+687) New Caledonia' },
    { countryCode: 'NZ', code: 64, text: '(+64) New Zealand' },
    { countryCode: 'NI', code: 505, text: '(+505) Nicaragua' },
    { countryCode: 'NE', code: 227, text: '(+227) Niger' },
    { countryCode: 'NG', code: 234, text: '(+234) Nigeria' },
    { countryCode: 'NU', code: 683, text: '(+683) Niue' },
    { countryCode: 'NF', code: 672, text: '(+672) Norfolk Islands' },
    { countryCode: 'NP', code: 670, text: '(+670) Northern Marianas' },
    { countryCode: 'NO', code: 47, text: '(+47) Norway' },
    { countryCode: 'OM', code: 968, text: '(+968) Oman' },
    { countryCode: 'PW', code: 680, text: '(+680) Palau' },
    { countryCode: 'PA', code: 507, text: '(+507) Panama' },
    { countryCode: 'PG', code: 675, text: '(+675) Papua New Guinea' },
    { countryCode: 'PY', code: 595, text: '(+595) Paraguay' },
    { countryCode: 'PE', code: 51, text: '(+51) Peru' },
    { countryCode: 'PH', code: 63, text: '(+63) Philippines' },
    { countryCode: 'PL', code: 48, text: '(+48) Poland' },
    { countryCode: 'PT', code: 351, text: '(+351) Portugal' },
    { countryCode: 'PR', code: 1787, text: '(+1787) Puerto Rico' },
    { countryCode: 'QA', code: 974, text: '(+974) Qatar' },
    { countryCode: 'RE', code: 262, text: '(+262) Reunion' },
    { countryCode: 'RO', code: 40, text: '(+40) Romania' },
    { countryCode: 'RU', code: 7, text: '(+7) Russia' },
    { countryCode: 'RW', code: 250, text: '(+250) Rwanda' },
    { countryCode: 'SM', code: 378, text: '(+378) San Marino' },
    { countryCode: 'ST', code: 239, text: '(+239) Sao Tome &amp; Principe' },
    { countryCode: 'SA', code: 966, text: '(+966) Saudi Arabia' },
    { countryCode: 'SN', code: 221, text: '(+221) Senegal' },
    { countryCode: 'CS', code: 381, text: '(+381) Serbia' },
    { countryCode: 'SC', code: 248, text: '(+248) Seychelles' },
    { countryCode: 'SL', code: 232, text: '(+232) Sierra Leone' },
    { countryCode: 'SG', code: 65, text: '(+65) Singapore' },
    { countryCode: 'SK', code: 421, text: '(+421) Slovak Republic' },
    { countryCode: 'SI', code: 386, text: '(+386) Slovenia' },
    { countryCode: 'SB', code: 677, text: '(+677) Solomon Islands' },
    { countryCode: 'SO', code: 252, text: '(+252) Somalia' },
    { countryCode: 'ZA', code: 27, text: '(+27) South Africa' },
    { countryCode: 'ES', code: 34, text: '(+34) Spain' },
    { countryCode: 'LK', code: 94, text: '(+94) Sri Lanka' },
    { countryCode: 'SH', code: 290, text: '(+290) St. Helena' },
    { countryCode: 'KN', code: 1869, text: '(+1869) St. Kitts' },
    { countryCode: 'SC', code: 1758, text: '(+1758) St. Lucia' },
    { countryCode: 'SD', code: 249, text: '(+249) Sudan' },
    { countryCode: 'SR', code: 597, text: '(+597) Suriname' },
    { countryCode: 'SZ', code: 268, text: '(+268) Swaziland' },
    { countryCode: 'SE', code: 46, text: '(+46) Sweden' },
    { countryCode: 'CH', code: 41, text: '(+41) Switzerland' },
    { countryCode: 'SI', code: 963, text: '(+963) Syria' },
    { countryCode: 'TW', code: 886, text: '(+886) Taiwan' },
    { countryCode: 'TJ', code: 7, text: '(+7) Tajikstan' },
    { countryCode: 'TH', code: 66, text: '(+66) Thailand' },
    { countryCode: 'TG', code: 228, text: '(+228) Togo' },
    { countryCode: 'TO', code: 676, text: '(+676) Tonga' },
    { countryCode: 'TT', code: 1868, text: '(+1868) Trinidad &amp; Tobago' },
    { countryCode: 'TN', code: 216, text: '(+216) Tunisia' },
    { countryCode: 'TR', code: 90, text: '(+90) Turkey' },
    { countryCode: 'TM', code: 7, text: '(+7) Turkmenistan' },
    { countryCode: 'TM', code: 993, text: '(+993) Turkmenistan' },
    {
        countryCode: 'TC',
        code: 1649,
        text: '(+1649) Turks &amp; Caicos Islands',
    },
    { countryCode: 'TV', code: 688, text: '(+688) Tuvalu' },
    { countryCode: 'UG', code: 256, text: '(+256) Uganda' },
    { countryCode: 'UA', code: 380, text: '(+380) Ukraine' },
    { countryCode: 'AE', code: 971, text: '(+971) United Arab Emirates' },
    { countryCode: 'UY', code: 598, text: '(+598) Uruguay' },
    { countryCode: 'UZ', code: 7, text: '(+7) Uzbekistan' },
    { countryCode: 'VU', code: 678, text: '(+678) Vanuatu' },
    { countryCode: 'VA', code: 379, text: '(+379) Vatican City' },
    { countryCode: 'VE', code: 58, text: '(+58) Venezuela' },
    { countryCode: 'VN', code: 84, text: '(+84) Vietnam' },
    { countryCode: 'VG', code: 84, text: '(+1284) Virgin Islands - British' },
    { countryCode: 'VI', code: 84, text: '(+1340) Virgin Islands - US' },
    { countryCode: 'WF', code: 681, text: '(+681) Wallis &amp; Futuna' },
    { countryCode: 'YE', code: 969, text: ')(+969) Yemen (Nort' },
    { countryCode: 'YE', code: 967, text: ')(+967) Yemen (Sout' },
    { countryCode: 'ZM', code: 260, text: '(+260) Zambia' },
    { countryCode: 'ZW', code: 263, text: '(+263) Zimbabwe' },
    { countryCode: 'CI', code: 225, text: '(+225) Ivory Coast' },
]);

export const ratingText = {
    RATING_GOOD: 'Avis de client satisfait (4-5 étoiles)',
    RATING_NEUTRAL: 'Avis de client neutre (3 étoiles)',
    RATING_BAD: 'Avis de client mécontent (1-2 étoiles)',
};

export const ratings = [ratingText.RATING_GOOD, ratingText.RATING_NEUTRAL, ratingText.RATING_BAD];

export const ratingEval = {
    GOOD_RATING: 'GOOD',
    NEUTRAL_RATING: 'NEUTRAL',
    BAD_RATING: 'BAD',
    NO_RATING: 'NO_RATING',
};

export enum CountriesWithEvents {
    FR = 'FR',
    GB = 'GB',
    US = 'US',
    CA = 'CA',
    AE = 'AE',
    MA = 'MA',
}

export const maxHashtagInIgPosts = 30;
export const eventTitleTextLimit = 58;
export const postCaptionTextLimit = 1500;
export const mapsterPostCaptionTextLimit = 300;

export const bannedHashtags = Object.freeze([
    'abdl',
    'addmysc',
    'adulting',
    'alone',
    'always',
    'armparty',
    'asiangirl',
    'ass',
    'assday',
    'attractive',
    'beautyblogger',
    'besties',
    'bikinibody',
    'boho',
    'brain',
    'babyrp',
    'bacak',
    'baddie',
    'balenciaga',
    'costumes',
    'curvygirls',
    'customers',
    'date',
    'dating',
    'desk',
    'direct',
    'dm',
    'edm',
    'eggplant',
    'elevator',
    'fitnessgirls',
    'fishnets',
    'girlsonly',
    'gloves',
    'hardworkpaysoff',
    'hawks',
    'hotweather',
    'humpday',
    'hustler',
    'ice',
    'instasport',
    'iphonegraphy',
    'italiano',
    'kansas',
    'kickoff',
    'killingit',
    'kissing',
    'loseweight',
    'lulu',
    'leaves',
    'like',
    'lean',
    'lingerie',
    'master',
    'mileycyrus',
    'milf',
    'mirrorphoto',
    'models',
    'mustfollow',
    'nasty',
    'newyearsday',
    'nudity',
    'overnight',
    'orderweedonline',
    'parties',
    'petite',
    'pornfood',
    'prettygirl',
    'pushups',
    'qatar',
    'rate',
    'ravens',
    'saltwater',
    'samelove',
    'selfharm',
    'single',
    'singlelife',
    'skateboarding',
    'skype',
    'snap',
    'snapchat',
    'tag4like',
    'tagsforlikes',
    'tanlines',
    'todayimwearing',
    'teens',
    'teen',
    'thought',
    'undies',
    'underage',
    'valentinesday',
    'workflow',
    'wtf',
    'xanax',
    'Youngmodel',
]);
export const refreshTime = Object.freeze({
    socialPosts: 60000 * 15,
});

// hours of the day with interval of 15min
export const times: string[] = [].concat
    .apply(
        ['24:00'],
        [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
        ].map((hour) => ['00', '15', '30', '45'].map((minutes) => `${hour}:${minutes}`))
    )
    .sort();

export enum AutoSaveState {
    SAVED = 'SAVED',
    NOT_SAVING = 'NOT_SAVING',
    SAVING = 'SAVING',
}

export enum ExtendedPostPublicationStatus {
    PENDING = PostPublicationStatus.PENDING,
    PUBLISHED = PostPublicationStatus.PUBLISHED,
    REJECTED = PostPublicationStatus.REJECTED,
    DRAFT = PostPublicationStatus.DRAFT,
    ERROR = PostPublicationStatus.ERROR,
    PUBLISHING = 'publishing',
    ACTIVE = 'active',
}

export const postsUpdateTexts = Object.freeze({
    GMB: ['Les horaires ont été mises à jour.', 'Hours were updated.'],
    FACEBOOK: [/a changé sa photo de/, /a actualisé son statut/, /updated their profile picture/, /updated their cover photo/],
});

export const breakpoints = Object.freeze({
    small: 639,
    medium: 769,
    large: 1023,
    very_large: 1279,
});

export const MALOU_MANAGER_EMAIL_ADDRESS = 'gestionclients@malou.io';

export const adminAuthList = ['gestionclients@malou.io', '232142364148787', '107323322263317'];

export const Mo = 1_000_000;

export const businessHours = Object.freeze({
    maxTimeSlotsPerDay: 3,
});

export const DEFAULT_CLOSED_TIME_PERIODS = Object.freeze(
    Object.keys(days)
        .map((day) => ({
            isClosed: true,
            isPrimaryPeriod: true,
            openDay: day as Day,
            openTime: '10:00',
            closeDay: day as Day,
            closeTime: '22:30',
        }))
        .map((period) => new TimePeriod(period))
);

export enum MediaEditionState {
    EDITING,
    FINISHED_EDITING,
}

export enum BindingIdKey {
    BINDING_ID = 'bindingId',
    MALOU_STORY_ID = 'malouStoryId',
}

export interface IStoryVideoCheck {
    duration: number;
    maxDuration: number;
    minDuration: number;
    isDurationValid: boolean;
    width: number;
    maxWidth: number;
    isWidthValid: boolean;
    height: number;
    ratio: number;
    maxRatio: number;
    minRatio: number;
    isRatioValid: boolean;
    extension: string;
    isHeightValid: boolean;
    isExtensionValid: boolean;
    error: boolean;
}

export const STARS_RATING = [1, 2, 3, 4, 5];

export const GIFT_PERCENTAGE_DECIMALS = 1;
// ################### JIMO ###################

export type JimoType = Window & typeof globalThis & { jimo: any[] } & { jimoInit: () => void };
export const JimoSupportedLanguages = ['en', 'fr', 'es', 'pt', 'it', 'de', 'jp', 'zh', 'sk'];
