import { NgClass } from '@angular/common';
import { Component, Inject, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PostSuggestioNotification } from ':core/components/notification-center/models/post-suggestion-notification.model';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

enum UpdateOptions {
    CLOSED = 'CLOSED',
    OPEN_AS_USUAL = 'OPEN_AS_USUAL',
}

@Component({
    selector: 'app-special-hour-popin',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, ApplyPurePipe, TranslateModule, NgClass],
    templateUrl: './special-hour-popin.component.html',
    styleUrl: './special-hour-popin.component.scss',
})
export class SpecialHourPopinComponent {
    readonly SvgIcon = SvgIcon;
    readonly UpdateOptions = UpdateOptions;

    selectedOption: WritableSignal<UpdateOptions | null> = signal(null);

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { restaurant: Restaurant; notification: PostSuggestioNotification },
        private readonly _dialogRef: MatDialogRef<SpecialHourPopinComponent>,
        private readonly _router: Router
    ) {}

    close(): void {
        this._dialogRef.close();
    }

    selectOption(option: UpdateOptions): void {
        this.selectedOption.set(option);
    }

    navigateToInformation(): void {
        this._router.navigate(['/restaurants', this.data.restaurant._id, 'seo', 'informations']);
        this.close();
    }

    updateHours(): void {
        switch (this.selectedOption()) {
            case UpdateOptions.CLOSED:
                // TODO: update hours to closed
                break;
            case UpdateOptions.OPEN_AS_USUAL:
                // TODO: get same hours as usual and update
                break;
        }
    }
}
