import { BaseEntity, EntityConstructor, PictureSize, PlatformKey } from '@malou-io/package-utils';

import { Notification } from './notification.model';

type NotificationPostProps = EntityConstructor<NotificationPost>;

export class NotificationPost extends BaseEntity {
    text: string;
    attachments: {
        [PictureSize.SMALL]: string;
        [PictureSize.IG_FIT]: string;
    };
    plannedPublicationDate: Date;
    restaurantId: string;
    errorMessage: string;
    platform: {
        id: string;
        key: PlatformKey;
        name: string;
        socialLink?: string;
    };

    constructor(notificationPost: NotificationPostProps) {
        super(notificationPost);
        this.text = notificationPost.text;
        this.attachments = notificationPost.attachments;
        this.plannedPublicationDate = notificationPost.plannedPublicationDate;
        this.restaurantId = notificationPost.restaurantId;
        this.errorMessage = notificationPost.errorMessage;
        this.platform = notificationPost.platform;
    }
}

export interface PostErrorNotificationData {
    restaurantIds: string[];
    post: NotificationPost;
}

type PostErrorNotificationProps = EntityConstructor<PostErrorNotification>;

export class PostErrorNotification extends Notification {
    data: PostErrorNotificationData;
    constructor(props: PostErrorNotificationProps) {
        super(props);
        this.data = {
            ...props.data,
            post: new NotificationPost(props.data.post),
        };
    }
}
