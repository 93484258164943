import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

const MAX_MEDIA = 10;

@Component({
    selector: 'app-media-picker',
    templateUrl: './media-picker.component.html',
    styleUrls: ['./media-picker.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatCheckboxModule,
        TranslateModule,
        ImagePathResolverPipe,
        ApplyPurePipe,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPickerComponent {
    @Input() multi: boolean;
    @Input() medias: WritableSignal<Media[]>;
    @Output() selected: EventEmitter<Media[]> = new EventEmitter<Media[]>();

    // Used to lose reference between this component and parent
    // If we work with selectedMedias without clonning it ([...value])
    // every time we select a media, it will be spread in parent component
    // BUT we want to spread media after click on validate
    bufferSelectedMedias: WritableSignal<Media[]> = signal([]);
    @Input() set selectedMedias(value: Media[]) {
        this.bufferSelectedMedias.set([...value]);
    }

    readonly SvgIcon = SvgIcon;

    toggleSelected(media: Media): void {
        const idx = this.bufferSelectedMedias().findIndex((m) => m.id === media.id);
        if (idx === -1) {
            if (this.bufferSelectedMedias().length < MAX_MEDIA) {
                if (this.multi) {
                    this.bufferSelectedMedias.update((currentBufferSelectedMedias) => [...currentBufferSelectedMedias, media]);
                } else {
                    this.selected.emit([media]);
                    return;
                }
            }
        } else {
            if (this.multi) {
                this.bufferSelectedMedias.update((currentBufferSelectedMedias) => {
                    currentBufferSelectedMedias.splice(idx, 1);
                    return [...currentBufferSelectedMedias];
                });
            } else {
                this.bufferSelectedMedias.set([]);
            }
        }
        this.selected.emit(this.bufferSelectedMedias());
    }

    isSelected(media: Media, bufferSelectedMedias: Media[]): boolean {
        return bufferSelectedMedias.findIndex((m) => m.id === media.id) !== -1;
    }
}
