import { AsyncPipe, JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    input,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { capitalize, countBy, maxBy } from 'lodash';
import objectHash from 'object-hash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';

import { KeywordsScoreDetailDto, KeywordsScoreFulfilledValueDto } from '@malou-io/package-dto';
import {
    ApplicationLanguage,
    BricksCategory,
    CountryCode,
    IBreakdown,
    isNotNil,
    KeywordConditionCriteria,
    KeywordScoreTextType,
    mapLanguageStringToApplicationLanguage,
    PartialRecord,
    removeDiacritics,
    toLowerCase,
} from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { LocalStorage } from ':core/storage/local-storage';
import { KeywordsScoreTipsComponent } from ':shared/components/keywords-score-gauge/keywords-score-tips/keywords-score-tips.component';
import { ScoreGaugeComponent } from ':shared/components/score-gauge/score-gauge.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Keyword, Restaurant } from ':shared/models/';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

import { editKeywordsScore } from './store/keywords-score.actions';
import { selectKeywordsScore } from './store/keywords-score.reducer';

interface KeywordScoreDetail {
    fulfilled: boolean;
    fulfilledValue: KeywordsScoreFulfilledValueDto;
    value: number;
}

type KeywordScoreRecord = {
    [textType in KeywordScoreTextType]: PartialRecord<KeywordConditionCriteria, KeywordScoreDetail>;
};

interface KeywordConditionCriteriaByTextType extends KeywordScoreRecord {
    [KeywordScoreTextType.POST]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.BRICKS_VARIETY]: KeywordScoreDetail;
        [KeywordConditionCriteria.RESTAURANT_NAME]: KeywordScoreDetail;
    };
    [KeywordScoreTextType.SHORT_DESCRIPTION]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.BRICKS_VARIETY]: KeywordScoreDetail;
        [KeywordConditionCriteria.RESTAURANT_NAME]: KeywordScoreDetail;
        [KeywordConditionCriteria.TEXT_LENGTH]: KeywordScoreDetail;
    };
    [KeywordScoreTextType.LONG_DESCRIPTION]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.BRICKS_VARIETY]: KeywordScoreDetail;
        [KeywordConditionCriteria.RESTAURANT_NAME]: KeywordScoreDetail;
        [KeywordConditionCriteria.TEXT_LENGTH]: KeywordScoreDetail;
    };
    [KeywordScoreTextType.HIGH_RATE_REVIEW]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.BRICKS_VARIETY]: KeywordScoreDetail;
        [KeywordConditionCriteria.RESTAURANT_NAME]: KeywordScoreDetail;
    };
    [KeywordScoreTextType.LOW_RATE_REVIEW]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.BRICKS_VARIETY]: KeywordScoreDetail;
        [KeywordConditionCriteria.RESTAURANT_NAME]: KeywordScoreDetail;
        [KeywordConditionCriteria.SORRY_WORDS]: KeywordScoreDetail;
    };
    [KeywordScoreTextType.HIGH_RATE_TEMPLATE]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.BRICKS_VARIETY]: KeywordScoreDetail;
    };
    [KeywordScoreTextType.LOW_RATE_TEMPLATE]: {
        [KeywordConditionCriteria.BRICKS_NUMBER]: KeywordScoreDetail;
        [KeywordConditionCriteria.SORRY_WORDS]: KeywordScoreDetail;
    };
}

type ConditionsInterface<T extends KeywordScoreTextType> = KeywordConditionCriteriaByTextType[T];

export interface Indication {
    fulfilled: boolean;
    currentValue: string;
    textType: KeywordConditionCriteria;
    shouldDisplayCurrentValue: boolean;
    shouldDisplaySubText: boolean;
    optimalValue?: number;
    minimumValue?: number;
    maximumValue?: number;
}

@Component({
    selector: 'app-keywords-score-gauge',
    templateUrl: './keywords-score-gauge.component.html',
    styleUrls: ['./keywords-score-gauge.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        ScoreGaugeComponent,
        ApplyPurePipe,
        JsonPipe,
        AsyncPipe,
        IncludesPipe,
        KeywordsScoreTipsComponent,
        SelectComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsScoreGaugeComponent implements OnInit {
    @ViewChild('scoregauge') scoreGaugeRef: ElementRef;

    readonly langOptions = input<(ApplicationLanguage | string)[]>(Object.values(ApplicationLanguage));
    @Input() text$: Observable<string>;
    @Input() textType$: Observable<KeywordScoreTextType>;
    @Input() restaurant$: Observable<Restaurant | null>;
    @Input() keywords$: Observable<Keyword[]>;
    @Input() lang$: Observable<string | null> = new BehaviorSubject<string | null>(null);
    @Input() withLargeDetails = false;
    @Input() givenScore: number;
    @Input() shouldDisplayScore = true;
    @Input() shouldDisplayTips = false;
    @Input() shouldDisplayKeywords = false;
    @Input() shouldDisplayKeywordsCategory = true;
    @Input() shouldShowTipsInTooltip = false;
    @Input() responseTime$: Observable<number> = new BehaviorSubject<number>(0);
    @Input() reviewerName$: Observable<string | undefined> = new BehaviorSubject<string | undefined>('');
    @Input() parentElementId$: Observable<string | null> = of(null);
    @Input() restaurantAiSettings$: Observable<RestaurantAiSettings | undefined> = of(undefined);
    @Input() shouldOnlyDisplayScore = false;
    @Input() title = this._translate.instant('keywords.score');
    @Input() shouldCacheScore = true;

    @Output() addKeyword: EventEmitter<string> = new EventEmitter();
    @Output() indicationListChanged: EventEmitter<Indication[]> = new EventEmitter();
    @Output() langChanged: EventEmitter<string> = new EventEmitter();

    readonly trackByTextFn = TrackByFunctionFactory.get('text');
    readonly langTranslations = this._translate.instant('common.langs');

    readonly indicationsList: WritableSignal<Indication[]> = signal([]);
    readonly formattedIndicationsList: Signal<string> = computed(() => {
        const intro = this._translate.instant('keywords_score.tips') + ' : ';
        const missingInfos = this.indicationsList()
            ?.filter((indication) => !indication.fulfilled)
            ?.map(({ shouldDisplayCurrentValue, textType, currentValue, optimalValue }) => {
                const text = this._translate.instant('keywords_gauge.' + textType + '.maintext');
                const value = shouldDisplayCurrentValue ? `${currentValue}/${optimalValue}` : '';
                return '- ' + text + ' ' + value;
            });
        return missingInfos.length ? [intro, ...missingInfos].join('\n') : '';
    });
    readonly score = signal(0);
    readonly bricks$ = new BehaviorSubject<IBreakdown[]>([]);
    readonly bricksFound: WritableSignal<string[]> = signal([]);
    readonly bricksClickedOn: WritableSignal<string[]> = signal([]);
    readonly isBrickChecked = computed(() => (brick: IBreakdown): boolean => {
        const isBrickFoundInText = this.bricksFound().some((brickFound) => this._brickComparator(brick, brickFound));
        const hasBrickBeenClickedOn = this.bricksClickedOn().some((brickClickedOn) => this._brickComparator(brick, brickClickedOn));
        return isBrickFoundInText || hasBrickBeenClickedOn;
    });

    readonly categoryMapping = {
        venueCategory: this._translate.instant('keywords.categories.venue_category'),
        venueType: this._translate.instant('keywords.categories.venue_type'),
        venueSpecial: this._translate.instant('keywords.categories.venue_special'),
        venueLocation: this._translate.instant('keywords.categories.venue_location'),
        touristicArea: this._translate.instant('keywords.categories.touristic_area'),
        station: this._translate.instant('keywords.categories.station'),
        venueOffer: this._translate.instant('keywords.categories.venue_offer'),
        venueEquipment: this._translate.instant('keywords.categories.venue_equipment'),
        venueAttribute: this._translate.instant('keywords.categories.venue_attribute'),
        venueAudience: this._translate.instant('keywords.categories.venue_audience'),
        venueLabel: this._translate.instant('keywords.categories.venue_label'),
        customerInput: this._translate.instant('keywords.categories.customer_input'),
        restaurantName: this._translate.instant('keywords.categories.restaurant_name'),
    };
    mostOccurrencesLang: string | null = null;
    readonly brickLangControl: FormControl<string> = new FormControl();
    readonly brickLangControlValue = toSignal(this.brickLangControl.valueChanges.pipe(startWith(null)), {
        initialValue: this.brickLangControl.value,
    });

    readonly brickTranslated = computed(() => (brick: IBreakdown): string => {
        const lang = this.brickLangControlValue();
        return (lang && brick.translations?.[lang]) ?? brick.text;
    });

    readonly restaurantAiSettings = signal<RestaurantAiSettings | undefined>(undefined);

    private _text: string;
    private _previousParentElementId: string | null = null;

    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;

    constructor(
        private readonly _translate: TranslateService,
        private _keywordsService: KeywordsService,
        private _store: Store
    ) {}

    ngOnInit(): void {
        combineLatest([
            this.text$,
            this.textType$,
            this.keywords$,
            this.restaurant$,
            this.lang$,
            this.brickLangControl.valueChanges.pipe(startWith(null)),
            this.reviewerName$,
            this.responseTime$,
            this.parentElementId$,
            this.restaurantAiSettings$,
        ])
            .pipe(
                debounceTime(400),
                filter(([_text, _textType, _keywords, restaurant]) => isNotNil(restaurant)),
                switchMap(
                    ([
                        text,
                        textType,
                        keywords,
                        restaurant,
                        lang,
                        selectedBricksLang,
                        reviewerName,
                        responseTime,
                        parentElementId,
                        restaurantAiSettings,
                    ]: [
                        string,
                        KeywordScoreTextType,
                        Keyword[],
                        Restaurant,
                        string | null,
                        ApplicationLanguage,
                        string,
                        number,
                        string | null,
                        RestaurantAiSettings | undefined,
                    ]) => {
                        this.restaurantAiSettings.set(restaurantAiSettings);

                        const bricksLang = this._getBricksLang(
                            keywords,
                            lang,
                            selectedBricksLang,
                            parentElementId !== this._previousParentElementId
                        );
                        if (bricksLang !== lang) {
                            this.langChanged.emit(bricksLang);
                        }
                        this._previousParentElementId = parentElementId;

                        const restaurantName =
                            this._isReviewTextType(textType) && restaurantAiSettings?.restaurantName
                                ? restaurantAiSettings.restaurantName
                                : restaurant.name;

                        const bricks = this._buildBricksFromKeywords(keywords, bricksLang, reviewerName, textType, restaurantName);
                        this.bricks$.next(bricks);
                        this._text = this.cleanText(text);

                        const bricksToProcessScoreWith = bricks
                            .filter(
                                (brick) =>
                                    brick.category !== BricksCategory.RESTAURANT_NAME && brick.category !== BricksCategory.REVIEWER_NAME
                            )
                            .map((brick) => this.brickTranslated()(brick));

                        const body = {
                            text: this._text,
                            textType,
                            bricks: bricksToProcessScoreWith,
                            venueName: this.cleanText(restaurantName, false),
                            language: bricksLang,
                            reviewerName: this.cleanText(reviewerName, false),
                            responseTime,
                        };
                        const key = objectHash(body);

                        return this._store.select(selectKeywordsScore(key)).pipe(
                            switchMap((score) => {
                                if (score) {
                                    return of(score);
                                }
                                return this._keywordsService
                                    .processKeywordsScore$({ ...body, shouldCacheScore: this.shouldCacheScore })
                                    .pipe(
                                        map((res) => {
                                            if (this.shouldCacheScore) {
                                                this._store.dispatch(editKeywordsScore({ [key]: res }));
                                            }
                                            return res;
                                        })
                                    );
                            })
                        );
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((res) => {
                // Since we don't send restaurant name brick to score lambda, we need to manually check the brick if the lambda score return that the restaurant name criteria is fulfilled
                this.bricksFound.update(() => {
                    const restaurantCriteria = res.details?.find((detail) => detail.criteria === KeywordConditionCriteria.RESTAURANT_NAME);
                    const reviewerNameCriteria = res.details?.find((detail) => detail.criteria === KeywordConditionCriteria.REVIEWER_NAME);
                    const restaurantNameBrick = this.bricks$.value.find((brick) => brick.category === BricksCategory.RESTAURANT_NAME);
                    const reviewerNameBrick = this.bricks$.value.find((brick) => brick.category === BricksCategory.REVIEWER_NAME);
                    return [
                        ...(res.bricksFound ?? []),
                        ...(restaurantCriteria?.fulfilled && restaurantNameBrick ? [restaurantNameBrick.text] : []),
                        ...(reviewerNameCriteria?.fulfilled && reviewerNameBrick ? [reviewerNameBrick.text] : []),
                    ];
                });
                this.bricksClickedOn.set([]);
                this.indicationsList.set(this._computeIndications(res.details ?? []));
                const score = res.score;
                this.score.set(this.givenScore ?? score);
            });
    }

    cleanText(text: string = '', shouldTransformToLowerCase = true): string {
        const cleanText = removeDiacritics(text).trim();
        return (shouldTransformToLowerCase ? cleanText?.toLowerCase() : cleanText) || '';
    }

    addBrick(brick: IBreakdown): void {
        this.bricksClickedOn.update((bricksClickedOn) => [...bricksClickedOn, this.brickTranslated()(brick)]);
        this.addKeyword.emit(this.brickTranslated()(brick));
    }

    brickLangDisplayFn = (lang: ApplicationLanguage | string): string => {
        const isApplicationLanguage = ApplicationLanguage[lang] !== undefined;
        const isHandledCountryCode = Object.values(CountryCode).map(toLowerCase).includes(lang);
        return isApplicationLanguage || isHandledCountryCode ? this._translate.instant(`common.langs.${lang}`) : capitalize(lang);
    };

    getIdSuffixFn = (lang: ApplicationLanguage): string => lang;

    private _computeIndications(details: KeywordsScoreDetailDto[]): Indication[] {
        const keyConditions = details.map((detail) => detail.criteria);
        const currentIndicationsObject = this._buildCurrentIndicationsObject(details);
        const indications = keyConditions.map((condition) => ({
            fulfilled: currentIndicationsObject[condition].fulfilled,
            currentValue: currentIndicationsObject[condition].currentValue,
            optimalValue: currentIndicationsObject[condition].fulfilledValue?.optimalValue,
            minimumValue: currentIndicationsObject[condition].fulfilledValue?.minimumValue,
            maximumValue: currentIndicationsObject[condition].fulfilledValue?.maximumValue,
            textType: condition,
            shouldDisplayCurrentValue: this._shouldDisplayCurrentValueForCondition(condition),
            shouldDisplaySubText: this._shouldDisplaySubText(condition),
        }));
        this.indicationListChanged.emit(indications);
        return indications;
    }

    private _brickComparator(brick: IBreakdown, brickFound: string): boolean {
        return this.brickTranslated()(brick)?.toLowerCase() === brickFound.toLowerCase();
    }

    private _getBricksLang(
        keywords: Keyword[],
        lang: string | null,
        selectedBricksLang: string | null,
        parentElementHasChanged: boolean
    ): string {
        const mostOccurrencesLang = this._getMostOccurrencesKeywordsLang(keywords);
        const defaultLang = mostOccurrencesLang || LocalStorage.getLang();
        lang = lang && this.langOptions().includes(lang as ApplicationLanguage) ? lang : defaultLang;

        const bricksLang = parentElementHasChanged ? lang : (selectedBricksLang ?? lang ?? defaultLang);
        if (bricksLang !== this.brickLangControl.value) {
            this.brickLangControl.setValue(bricksLang, { emitEvent: true });
        }
        return bricksLang;
    }

    private _buildBricksFromKeywords(
        keywords: Keyword[],
        lang: string,
        reviewerName: string | undefined,
        textType: KeywordScoreTextType,
        restaurantName: string
    ): IBreakdown[] {
        if (textType === KeywordScoreTextType.LOW_RATE_REVIEW || textType === KeywordScoreTextType.LOW_RATE_TEMPLATE) {
            const defaultBricks = [{ text: restaurantName, category: BricksCategory.RESTAURANT_NAME, lang: 'fr' }];
            if (reviewerName) {
                defaultBricks.push({ text: reviewerName, category: BricksCategory.REVIEWER_NAME, lang: 'fr' });
            }
            return defaultBricks;
        }

        const selectedKeywords = (keywords || []).filter((keyword) => keyword.selected);

        const bricks: IBreakdown<string>[] = selectedKeywords
            .map((keyword): IBreakdown<string>[] => {
                const customKeyword = {
                    text: keyword.text?.toLowerCase(),
                    category: 'customerInput',
                    lang: keyword.language,
                };
                const defaultBricks = keyword.isCustomerInput ? [customKeyword] : [];

                return keyword.bricks?.length
                    ? keyword.bricks.filter((brick) => keyword.language === lang || !!brick.translations?.[lang])
                    : defaultBricks;
            })
            .flat()
            .filter((brick) => this.brickTranslated()(brick))
            .sort((a, _b) => {
                if (a.category === 'customerInput') {
                    return -1;
                }
                return 1;
            }) // used for next step, customerInput is prio if another brick has same text
            .filter((brick, index, self) => {
                const foundIndex = self.findIndex((t) => this.brickTranslated()(t) === this.brickTranslated()(brick));
                return index === foundIndex;
            }) // remove duplicate text bricks in current lang
            .sort((a, b) => {
                if (a.text.length < b.text.length) {
                    return 1;
                }
                return -1;
            });

        if (reviewerName) {
            bricks.unshift({ text: reviewerName, category: BricksCategory.REVIEWER_NAME });
        }
        bricks.unshift({ text: restaurantName, category: BricksCategory.RESTAURANT_NAME });

        return bricks;
    }

    private _getMostOccurrencesKeywordsLang(keywords: Keyword[]): ApplicationLanguage | null {
        if (keywords.length === 0) {
            return null;
        }
        const langs = keywords.filter((k) => k.selected).map((keyword) => keyword.language);
        if (langs.length === 0) {
            return null;
        }
        const langOccurrences = countBy(langs);
        const langOccurrencesKeys = Object.keys(langOccurrences);
        const getMostOccurrencesKeywordsLang = maxBy(langOccurrencesKeys, (lang) => langOccurrences[lang]) ?? null;
        return getMostOccurrencesKeywordsLang ? mapLanguageStringToApplicationLanguage(getMostOccurrencesKeywordsLang) : null;
    }

    private _buildCurrentIndicationsObject<T extends KeywordScoreTextType>(details: KeywordsScoreDetailDto[]): ConditionsInterface<T> {
        return (
            details?.reduce((acc, detail) => {
                const condition = detail.criteria;
                acc[condition] = {
                    ...acc[condition],
                    fulfilled: detail.fulfilled,
                    fulfilledValue: detail.fulfilledValue,
                    currentValue: detail.value,
                };
                return acc;
            }, {} as ConditionsInterface<T>) ?? {}
        );
    }

    private _shouldDisplayCurrentValueForCondition(condition: KeywordConditionCriteria): boolean {
        return ![
            KeywordConditionCriteria.RESTAURANT_NAME,
            KeywordConditionCriteria.RESPONSE_TIME,
            KeywordConditionCriteria.REVIEWER_NAME,
        ].includes(condition);
    }

    private _shouldDisplaySubText(condition: KeywordConditionCriteria): boolean {
        return [KeywordConditionCriteria.BRICKS_VARIETY, KeywordConditionCriteria.SORRY_WORDS].includes(condition);
    }

    private _isReviewTextType(textType: KeywordScoreTextType): boolean {
        return [KeywordScoreTextType.LOW_RATE_REVIEW, KeywordScoreTextType.HIGH_RATE_REVIEW].includes(textType);
    }
}
