import { ChangeDetectorRef, Component, OnDestroy, signal, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationPopinsComponent } from ':core/components/notification-center/components/notification-popins/notification-popins.component';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { NotificationCenterComponent } from ':core/components/notification-center/notification-center.component';
import { FooterManagerComponent } from ':core/components/restaurant/footer-manager/footer-manager.component';
import { PermissionsHeadbandComponent } from ':core/components/restaurant/permissions-headband/permissions-headband.component';
import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { SidenavContentComponent } from ':modules/sidenav-router/sidenav-content/sidenav-content.component';
import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { HeaderComponent } from ':shared/components/header/header.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';

@Component({
    selector: 'app-sidenav-router',
    templateUrl: './sidenav-router.component.html',
    styleUrls: ['./sidenav-router.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        RouterOutlet,
        MatSidenavModule,
        SidenavContentComponent,
        FooterManagerComponent,
        PermissionsHeadbandComponent,
        NotificationCenterComponent,
        NotificationPopinsComponent,
    ],
})
@AutoUnsubscribeOnDestroy()
export class SidenavRouterComponent implements OnDestroy, KillSubscriptions {
    readonly killSubscriptions$ = new Subject<void>();

    observer: ResizeObserver;
    matSidenavWidth: number;
    isSmallScreen = false;
    isSidenavOpened = false;
    isAggregatedView = signal(false);

    constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _screenSizeService: ScreenSizeService,
        public readonly notificationCenterContext: NotificationCenterContext,
        public readonly experimentationService: ExperimentationService
    ) {
        this._store
            .select(SidenavSelectors.selectIsOpened)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((isOpened) => {
                this.isSidenavOpened = isOpened;
            });

        this.observer = new ResizeObserver((entries) => {
            this.matSidenavWidth = entries[0].contentRect.width;
            this._changeDetectorRef.detectChanges();
        });

        this._router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                const isAggregatedView = event.url.includes('/groups');
                this.isAggregatedView.set(isAggregatedView);
            }
            if (event instanceof NavigationStart && this.isSmallScreen) {
                this._store.dispatch(SidenavActions.close());
            }
        });

        this._screenSizeService.resize$.pipe(takeUntil(this.killSubscriptions$)).subscribe((elt) => {
            this.isSmallScreen = elt.size === ScreenSize.IsSmallScreen;
        });
    }

    @ViewChild(MatSidenav) set matSidenav(matSidenav: MatSidenav) {
        this.observer.observe(matSidenav._content.nativeElement);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }
}
