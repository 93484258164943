import { CaslRole } from '../../constants';

export enum NotificationChannel {
    EMAIL = 'email',
    MOBILE = 'mobile',
    WEB = 'web',
}

export enum NotificationType {
    REVIEW = 'review',
    REVIEW_REPLY_REMINDER = 'review_reply_reminder',
    SPECIAL_HOUR = 'special_hour',
    POST_SUGGESTION = 'post_suggestion',
    POST_ERROR = 'post_error',
}

export enum NotificationSettingsProperty {
    REVIEW_REPLY_REMINDER = 'reviewReplyReminder',
    SPECIAL_HOUR = 'specialHourReminder',
    POST_SUGGESTION = 'postSuggestion',
}

export const NEGATIVE_REVIEW_MAX_RATING = 3;
export const NEGATIVE_REVIEW_MAX_DAYS_NOTIFICATION = 30;
export const NEGATIVE_REVIEW_MIN_DAYS_NOTIFICATION = 2;

export const PERIOD_FOR_EMAIL_POSTS_SUGGESTION = 10;
export const PERIOD_FOR_WEB_POSTS_SUGGESTION = 14;

export const NOTIFICATIONS_ROLE = Object.freeze({
    [NotificationType.REVIEW_REPLY_REMINDER]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.POST_SUGGESTION]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR],
    [NotificationType.SPECIAL_HOUR]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR],
});

export enum SpecialHourNotificationAction {
    NO_CHANGE = 'NO_CHANGE',
    CLOSED = 'CLOSED',
    NOTHING = 'NOTHING',
}
