import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, Signal, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, debounceTime, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { GetNotificationsQueryDto } from '@malou-io/package-dto';
import { NotificationChannel, NotificationType } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { RoiNotificationsContext } from ':modules/notification-center/notifications.context';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { editUserInfos } from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { MalouNotification } from ':shared/enums/notification';
import { runOptimistic } from ':shared/helpers/optimistic';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

import { IllustrationPathResolverPipe } from '../../../shared/pipes/illustration-path-resolver.pipe';
import { MalouSpinnerComponent } from '../spinner/spinner/malou-spinner.component';
import { NotificationItemActionsComponent } from './components/notification-item-actions/notification-item-actions.component';
import { NegativeReviewReminderNotificationItemComponent } from './components/notification-item/negative-review-reminder-notification-item/negative-review-reminder-notification-item.component';
import { PostErrorNotificationItemComponent } from './components/notification-item/post-error-notification-item/post-error-notification-item.component';
import { PostSuggestionNotificationItemComponent } from './components/notification-item/post-suggestion-notification-item/post-suggestion-notification-item.component';
import { ReviewNotificationItemComponent } from './components/notification-item/review-notification-item/review-notification-item.component';
import { RoiNotificationItemComponent } from './components/notification-item/roi-notification-item/roi-notification-item.component';
import { SpecialHourNotificationComponent } from './components/notification-item/special-hour-notification-item/special-hour-notification-item.component';
import { NotificationCenterContext } from './context/notification-center.context';
import { Notification } from './models/notification.model';
import { NotificationService } from './services/notifications.service';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        NegativeReviewReminderNotificationItemComponent,
        NotificationItemActionsComponent,
        ReviewNotificationItemComponent,
        SpecialHourNotificationComponent,
        PostSuggestionNotificationItemComponent,
        MatButtonModule,
        MatIconModule,
        InfiniteScrollDirective,
        SkeletonComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ApplyPurePipe,
        RoiNotificationItemComponent,
        MatMenuModule,
        LazyLoadImageModule,
        NoopMatCheckboxComponent,
        ApplyPurePipe,
        IllustrationPathResolverPipe,
        PostErrorNotificationItemComponent,
    ],
})
@AutoUnsubscribeOnDestroy()
export class NotificationCenterComponent implements OnInit, KillSubscriptions {
    private readonly _notificationService = inject(NotificationService);
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _userService = inject(UsersService);
    private readonly _toastService = inject(ToastService);
    private readonly _httpErrorPipe = inject(HttpErrorPipe);
    readonly notificationCenterContext = inject(NotificationCenterContext);

    readonly NotificationType = NotificationType;
    readonly SvgIcon = SvgIcon;
    readonly PAGE_SIZE = 20;
    readonly MOCK_ARRAY = new Array(5).fill(0);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly pagination$ = new BehaviorSubject<GetNotificationsQueryDto>({
        pageNumber: 0,
        pageSize: this.PAGE_SIZE,
        notificationTypes: [
            NotificationType.REVIEW,
            NotificationType.REVIEW_REPLY_REMINDER,
            NotificationType.POST_SUGGESTION,
            NotificationType.SPECIAL_HOUR,
            NotificationType.POST_ERROR,
        ],
        channel: NotificationChannel.WEB,
        restaurantIds: [],
    });

    readonly loading = signal(false);
    readonly roiNotifications = computed(() =>
        this._roiNotificationsContext.notifications().filter((n) => n.id !== MalouNotification.ROI_SETTINGS_UPDATED)
    );

    readonly currentUser = this._store.selectSignal(selectUserInfos);
    readonly userRestaurants = computed(() =>
        [...this._store.selectSignal(selectOwnRestaurants)()].sort((a, b) => a.name.localeCompare(b.name))
    );
    readonly notificationFilters = computed(() => this.currentUser()?.settings?.notifications?.web.filters);

    ngOnInit(): void {
        this._store.select(selectUserInfos).subscribe({
            next: (user) => {
                if (user) {
                    this.loading.set(true);
                    this.notificationCenterContext.notifications.set([]);
                    this.pagination$.next({
                        ...this.pagination$.value,
                        pageNumber: 0,
                        restaurantIds: user.settings?.notifications?.web.filters.restaurantIds,
                    });
                }
            },
        });
        this.pagination$
            .pipe(
                debounceTime(300),
                tap(() => this.loading.set(true)),
                switchMap((query) => this._notificationService.getNotifications(query)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (notifications) => {
                    this.notificationCenterContext.notifications.update((prev) => uniqBy([...prev, ...notifications], 'id'));
                    this.loading.set(false);
                },
                error: (error) => {
                    console.error(error);
                    this.loading.set(false);
                },
            });
        this._notificationService.notificationReceived$.pipe(takeUntil(this.killSubscriptions$)).subscribe({
            next: (notification) => {
                this.notificationCenterContext.addNewNotification(notification);
                this.notificationCenterContext.displayNewNotification(notification);
            },
        });
    }

    onScroll(): void {
        this.pagination$.next({
            ...this.pagination$.value,
            pageNumber: this.pagination$.value.pageNumber + 1,
        });
    }

    areAllPreviousNotificationsRead = (notification: Notification, notifications: Notification[]): boolean => {
        const notificationsBefore = notifications.filter((n) => n.createdAt < notification.createdAt);
        const notificationsAfter = notifications.filter((n) => n.createdAt > notification.createdAt);
        return notificationsBefore.every((n) => n.isRead()) && !notificationsAfter.some((n) => n.isRead());
    };

    redirectToNotificationSettings(): void {
        this._router.navigate(['/users', this.currentUser()!._id, 'notifications-settings']);
        this.notificationCenterContext.close();
    }

    toggleRestaurantFilterItem(restaurantId: string): void {
        const checked = this.isRestaurantFilterChecked(
            restaurantId,
            this.currentUser()!.settings!.notifications?.web.filters.restaurantIds
        );
        const restaurantIds = checked
            ? this.currentUser()!.settings!.notifications?.web.filters.restaurantIds.filter((id) => id !== restaurantId)
            : [...this.currentUser()!.settings!.notifications?.web.filters.restaurantIds, restaurantId];

        const updatedUser = this.currentUser()?.updateSetting('notifications.web.filters.restaurantIds', restaurantIds);
        this._updateUser(updatedUser!);
    }

    isRestaurantFilterChecked = (restaurantId: string, restaurantIds: string[]): boolean => restaurantIds.includes(restaurantId);

    private _updateUser(update: User): void {
        runOptimistic({
            state: this.currentUser as Signal<User | null>,
            observable: this._userService.update(this.currentUser()!._id, { settings: update.settings }),
            optimisticUpdate: () => {
                this._store.dispatch(editUserInfos({ infos: update }));
            },
            onError: (error, previousState) => {
                console.error(error);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(error));
                this._store.dispatch(editUserInfos({ infos: previousState()! }));
            },
        });
    }
}
