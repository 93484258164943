<app-modal-structure
    [title]="titleTranslationKey() | translate"
    [titleImage]="
        showPlatformIconInModalTitle()
            ? { class: 'h-[25px] w-[25px] malou-logo', src: platformKey() | platformLogoPathResolver: { folder: 'platforms' } }
            : null
    "
    [primaryButtonText]="'common.validate' | translate"
    [primaryButtonDisabled]="!selectedCredential() || !selectedPlatformSearch()"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="isUpdatingPlatform()"
    (close)="close.emit({})"
    (primaryClick)="onValidate()"
    (secondaryClick)="goToStep.emit(goToStepParam())">
    @if (isCredentialsSearchInError()) {
        <ng-container *ngTemplateOutlet="credentialsSearchErrorTemplate"></ng-container>
    } @else if (credentials() === null) {
        <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
    } @else {
        <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
    }
</app-modal-structure>

<ng-template #mainTemplate>
    <div class="flex h-full flex-col gap-y-3">
        <app-platforms-connection-account-selector
            [title]="'platforms.connection_new.shared.steps.select_credential_and_business.select_credential' | translate"
            [selectedValue]="selectedCredential()"
            [selectableValues]="credentials()!"
            [displayWith]="credentialsDisplayWith"
            [showButton]="showNewOauthButton()"
            [disabled]="isUpdatingPlatform()"
            (buttonClick)="onNewOauthButtonClick()"
            (selectionChange)="onCredentialChange($event)"></app-platforms-connection-account-selector>
        @if (!hideResults()) {
            <app-platforms-connection-access-information
                [isValid]="platformSearchAccess().access?.isValid ?? true"
                [expirationTimestamp]="platformSearchAccess().access?.dataExpiresAt"></app-platforms-connection-access-information>
        }
        <div class="min-h-0 grow">
            @if (!hideResults()) {
                <app-platforms-connection-business-selector
                    [translations]="{
                        title: 'platforms.connection_new.shared.steps.select_credential_and_business.select_business',
                        noResultsDescription: 'platforms.connection_new.shared.steps.select_credential_and_business.no_results_description',
                    }"
                    [selectedValue]="selectedPlatformSearch()"
                    [compareWith]="platformSearchCompareWith"
                    [selectableValues]="platformSearchResults()"
                    [displayWith]="platformSearchDisplayWith"
                    [isDisabled]="isUpdatingPlatform()"
                    (selectionChange)="onBusinessChange($event)"></app-platforms-connection-business-selector>
            }
            @if (isPlatformSearchLoading()) {
                <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
            } @else if (isPlatformSearchInError()) {
                <ng-container *ngTemplateOutlet="platformsSearchErrorTemplate"></ng-container>
            }
        </div>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="flex h-full items-center justify-center"><app-malou-spinner></app-malou-spinner></div>
</ng-template>

<ng-template #platformsSearchErrorTemplate>
    <div class="flex h-full flex-col items-center justify-center gap-y-4">
        <img class="h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <div class="flex flex-col items-center gap-y-2">
            <div class="malou-color-text-1 malou-text-14--bold">
                {{ 'platforms.connection_new.shared.steps.select_credential_and_business.platforms_search_error_title' | translate }}
            </div>
            <div class="malou-text-10--regular malou-color-text-2">
                {{ 'platforms.connection_new.shared.steps.select_credential_and_business.platforms_search_error_description' | translate }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #credentialsSearchErrorTemplate>
    <div class="flex h-full flex-col items-center justify-center gap-y-4">
        <img class="h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <div class="flex flex-col items-center gap-y-2">
            <div class="malou-color-text-1 malou-text-14--bold">
                {{ 'platforms.connection_new.shared.steps.select_credential_and_business.credentials_search_error_title' | translate }}
            </div>
            <div class="malou-text-10--regular malou-color-text-2">
                {{
                    'platforms.connection_new.shared.steps.select_credential_and_business.credentials_search_error_description' | translate
                }}
            </div>
        </div>
    </div>
</ng-template>
