import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PostSource } from '@malou-io/package-utils';

import { PostSuggestioNotification } from ':core/components/notification-center/models/post-suggestion-notification.model';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-post-suggestion-popin',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, ApplyPurePipe, TranslateModule],
    templateUrl: './post-suggestion-popin.component.html',
    styleUrl: './post-suggestion-popin.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostSuggestionPopinComponent {
    readonly SvgIcon = SvgIcon;
    readonly PostSource = PostSource;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { restaurant: Restaurant; notification: PostSuggestioNotification },
        private readonly _dialogRef: MatDialogRef<PostSuggestionPopinComponent>,
        private readonly _router: Router
    ) {}

    close(): void {
        this._dialogRef.close();
    }

    navigateToPosts(source: PostSource): void {
        switch (source) {
            case PostSource.SOCIAL:
                this._router.navigate(['restaurants', this.data.restaurant._id, 'social', 'socialposts']);
                break;
            case PostSource.SEO:
                this._router.navigate(['restaurants', this.data.restaurant._id, 'seo', 'posts']);
                break;
        }
        this.close();
    }
}
