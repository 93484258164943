import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, isDevMode, LOCALE_ID } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withRouterConfig } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import Annotation from 'chartjs-plugin-annotation';
import { NgChartsModule } from 'ng2-charts';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { initSentry } from 'sentry';

import { APP_DEFAULT_LANGUAGE, isLangInApplicationLanguages } from '@malou-io/package-utils';

import { AuthGuard } from ':core/auth/auth.guard';
import { RoiAggregatedRestaurantsGuard, RoiRestaurantGuard } from ':core/auth/roi.guard';
import { RoleGuard } from ':core/auth/role.guard';
import * as footerManagerReducer from ':core/components/restaurant/footer-manager/store/footer-manager.reducer';
import { PermissionsEffect } from ':core/credentials/store/permissions.effects';
import * as permissionsReducer from ':core/credentials/store/permissions.reducer';
import { MyErrorInterceptor } from ':core/interceptors/my-error.interceptor';
import { RedirectToProductionGuard } from ':core/maintenance/maintenance.guard';
import { MyMaterialModule } from ':core/my-material-module/my-material.module';
import { LocalStorage } from ':core/storage/local-storage';
import { logout, storageMetaReducer } from ':core/storage/storage.metareducer';
import { AdminEffect } from ':modules/admin/store/admin.effects';
import * as adminReducer from ':modules/admin/store/admin.reducer';
import * as aggregatedStatisticsReducer from ':modules/aggregated-statistics/store/aggregated-statistics.reducer';
import * as campaignsReducer from ':modules/campaigns/store/campaigns.reducer';
import * as commentsReducer from ':modules/comments/store/comments.reducer';
import * as galleryImportMediaReducer from ':modules/gallery/gallery-import-media/gallery-import-media.reducer';
import * as galleryReducer from ':modules/gallery/gallery.reducer';
import * as generatorReducer from ':modules/generator/store/generation-forms.reducer';
import { SuggestionEffect } from ':modules/informations/information-suggestions-modal/store/suggestions.effects';
import * as suggestionReducer from ':modules/informations/information-suggestions-modal/store/suggestions.reducer';
import * as informationsReducer from ':modules/informations/store/informations.reducer';
import { JimoEffect } from ':modules/jimo/jimo.effects';
import * as jimoReducer from ':modules/jimo/jimo.reducer';
import { KeywordsEffect } from ':modules/keywords/store/keywords.effects';
import * as keywordsReducer from ':modules/keywords/store/keywords.reducer';
import * as messagesReducer from ':modules/messages/messages.reducer';
import { PlatformsEffect } from ':modules/platforms/store/platforms.effects';
import * as platformsReducer from ':modules/platforms/store/platforms.reducer';
import * as postsReducer from ':modules/posts/posts.reducer';
import { RestaurantsEffect } from ':modules/restaurant-list/restaurant-list.effects';
import * as restaurantsReducer from ':modules/restaurant-list/restaurant-list.reducer';
import { ReviewsEffect } from ':modules/reviews/store/reviews.effect';
import * as reviewsReducer from ':modules/reviews/store/reviews.reducer';
import * as sidenavReducer from ':modules/sidenav-router/store/sidenav.reducer';
import * as socialPostsReducer from ':modules/social-posts/social-posts.reducer';
import * as statisticsReducer from ':modules/statistics/store/statistics.reducer';
import * as storiesReducer from ':modules/stories/store/stories.reducer';
import * as messageTemplatesReducer from ':modules/templates/message-templates/store/message-templates.reducer';
import * as reviewTemplatesReducer from ':modules/templates/review-templates/store/review-templates.reducer';
import { UserEffect } from ':modules/user/store/user.effects';
import * as userReducer from ':modules/user/store/user.reducer';
import * as keywordsScoreReducer from ':shared/components/keywords-score-gauge/store/keywords-score.reducer';
import * as nfcReducer from ':shared/components/shared-nfc/store/nfc.reducer';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { defaultChartJsConfiguration } from ':shared/helpers/default-chart-js-configuration';
import { IconModule } from ':shared/modules/icon.module';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routing';
import { environment } from './environments/environment';
import './luxon-fix';

initSentry();

if (environment.production) {
    // PRODUCTION ENV
    enableProdMode();
}

export function appTranslationInitializerFactory(translate: TranslateService) {
    return (): Promise<any> =>
        new Promise<any>((resolve: any) => {
            const langToSet = LocalStorage.getLang();
            translate.setDefaultLang(langToSet);
            translate.use(langToSet).subscribe({
                next: () => {
                    console.info(`Successfully initialized '${langToSet}' language.`);
                },
                error: (err) => {
                    console.error(`Problem with '${langToSet}' language initialization.`);
                    console.error(err);
                },
                complete: () => {
                    resolve(null);
                },
            });
        });
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            // AppRoutingModule,
            MyMaterialModule,
            JwtModule.forRoot({
                config: {
                    tokenGetter: tokenGetter,
                    allowedDomains: [environment.APP_MALOU_API_URL.split('://')[1]],
                    disallowedRoutes: [],
                },
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpBackend],
                },
                defaultLanguage: _getDefaultLanguage(),
            }),
            DragToSelectModule.forRoot({
                selectedClass: 'selected-item',
                shortcuts: {
                    disableSelection: 'meta',
                },
            }),
            NgChartsModule.forRoot({
                // @ts-ignore // because defaults object is not deep partial (only partial at first level)
                defaults: defaultChartJsConfiguration,
                plugins: [Annotation],
                generateColors: true,
            }),
            StoreModule.forRoot(
                {
                    admin: adminReducer.reducer,
                    aggregatedStatistics: aggregatedStatisticsReducer.reducer,
                    campaigns: campaignsReducer.reducer,
                    comments: commentsReducer.reducer,
                    footerManager: footerManagerReducer.reducer,
                    gallery: galleryReducer.reducer,
                    galleryImportMedia: galleryImportMediaReducer.reducer,
                    informations: informationsReducer.reducer,
                    keywords: keywordsReducer.reducer,
                    messages: messagesReducer.reducer,
                    messageTemplates: messageTemplatesReducer.reducer,
                    nfc: nfcReducer.reducer,
                    permissions: permissionsReducer.reducer,
                    platforms: platformsReducer.reducer,
                    posts: postsReducer.reducer,
                    reviewTemplates: reviewTemplatesReducer.reducer,
                    reviews: reviewsReducer.reducer,
                    restaurants: restaurantsReducer.reducer,
                    sidenav: sidenavReducer.reducer,
                    socialposts: socialPostsReducer.reducer,
                    statistics: statisticsReducer.reducer,
                    stories: storiesReducer.reducer,
                    suggestions: suggestionReducer.reducer,
                    user: userReducer.reducer,
                    jimoUserAttributes: jimoReducer.reducer,
                    generator: generatorReducer.reducer,
                    keywordsScore: keywordsScoreReducer.reducer,
                },
                {
                    metaReducers: [logout, storageMetaReducer],
                }
            ),
            environment.imports, // Contains among other things StoreDevtoolsModule.instrument({ maxAge: 25 })
            EffectsModule.forRoot([
                PlatformsEffect,
                AdminEffect,
                UserEffect,
                PermissionsEffect,
                RestaurantsEffect,
                SuggestionEffect,
                ReviewsEffect,
                KeywordsEffect,
                JimoEffect,
            ]),
            IconModule
        ),
        DatePipe,
        HttpErrorPipe,
        PlatformLogoPathResolverPipe,
        EnumTranslatePipe,
        PluralTranslatePipe,
        AvatarPipe,
        ShortNumberPipe,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => (): void => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyErrorInterceptor,
            multi: true,
        },
        RedirectToProductionGuard,
        MatPaginatorIntl,
        AuthGuard,
        RoleGuard,
        RoiRestaurantGuard,
        RoiAggregatedRestaurantsGuard,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(APP_ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        {
            provide: APP_INITIALIZER,
            useFactory: appTranslationInitializerFactory,
            deps: [TranslateService],
            multi: true,
        },
    ],
}).catch((err) => console.error(err));

registerLocaleData(localeFr);

export function tokenGetter(): string | null {
    try {
        // We may have an error when trying to access local storage on some Firefox versions,
        // https://stackoverflow.com/questions/23269532/localstorage-in-firefox-extension-throws-the-operation-is-insecure-exception
        // or if the user has blocked cookies in Safari
        // https://stackoverflow.com/questions/46632093/getting-security-error-on-iphone-when-using-localstorage
        // It happened sometimes when the users scan a QR code or scan a NFC tag
        // https://malou.sentry.io/issues/5363619381
        return localStorage.getItem(LocalStorageKey.JWT_TOKEN) ?? null;
    } catch (e) {
        console.error('Error while trying to get JWT token from local storage', e);
        return null;
    }
}

export function createTranslateLoader(httpBackend: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(httpBackend), '/assets/i18n/', '.json?cacheBuster=' + new Date().getTime());
}

function _getDefaultLanguage(): string {
    const userLang = navigator.language;
    let lang = isLangInApplicationLanguages(userLang) ? userLang : APP_DEFAULT_LANGUAGE;
    let localStorageLang: string | null = null;
    try {
        localStorageLang = LocalStorage.getLang();
    } catch (error) {
        // We may have an error when trying to access local storage on some Firefox versions,
        // https://stackoverflow.com/questions/23269532/localstorage-in-firefox-extension-throws-the-operation-is-insecure-exception
        // or if the user has blocked cookies in Safari
        // https://stackoverflow.com/questions/46632093/getting-security-error-on-iphone-when-using-localstorage
        // It happened sometimes when the users scan a QR code or scan a NFC tag
        // https://malou.sentry.io/issues/5363619381
        console.error('Error while trying to get language from local storage', error);
    }

    if (localStorageLang && isLangInApplicationLanguages(localStorageLang)) {
        lang = localStorageLang;
    }

    return lang;
}

// Add environment related scripts to header : GOOGLE_FRONTEND_KEY is different in dev and production environment
// const script = document.createElement('script');
//   script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_FRONTEND_KEY}&libraries=places&language=en`
//   document.head.appendChild(script);
