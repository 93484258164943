import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { AddUserToRestaurantWithEmailResponseDto } from '@malou-io/package-dto';
import {
    APP_DEFAULT_LANGUAGE,
    ApplicationLanguage,
    CaslRole,
    isNotNil,
    mapLanguageStringToApplicationLanguage,
    Role,
} from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { ReportsService } from ':core/services/report.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { selectUserRestaurants } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SelectLanguagesComponent } from ':shared/components/select-languages/select-languages.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { ApiResult, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { MapPipe } from ':shared/pipes/map.pipe';

interface CaslRoleDescription {
    key: CaslRole;
    text: string;
    subtext: string;
}

type CandidateForm = FormGroup<{
    usersFormArray: CandidateFormArray;
}>;

type CandidateFormArray = FormArray<
    FormGroup<{
        user: FormControl<Candidate | null>;
        caslRole: FormControl<CaslRole | null>;
        language: FormControl<ApplicationLanguage | null>;
    }>
>;

interface Candidate {
    _id?: string;
    email?: string;
    language: ApplicationLanguage | null;
    getDisplayedValue?: () => string;
}
@Component({
    selector: 'app-new-user-modal',
    templateUrl: './new-user-modal.component.html',
    styleUrls: ['./new-user-modal.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        SelectComponent,
        SelectLanguagesComponent,
        SelectRestaurantsComponent,
        AsyncPipe,
        MapPipe,
    ],
})
export class NewUserModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly CASL_ROLES: CaslRoleDescription[] = [
        {
            key: CaslRole.OWNER,
            text: this._translate.instant('roles.roles.owner'),
            subtext: this._translate.instant('roles.roles.owner_subtext'),
        },
        {
            key: CaslRole.EDITOR,
            text: this._translate.instant('roles.roles.editor'),
            subtext: this._translate.instant('roles.roles.editor_subtext'),
        },
        {
            key: CaslRole.MODERATOR,
            text: this._translate.instant('roles.roles.moderator'),
            subtext: this._translate.instant('roles.roles.moderator_subtext'),
        },
        {
            key: CaslRole.GUEST,
            text: this._translate.instant('roles.roles.guest'),
            subtext: this._translate.instant('roles.roles.guest_subtext'),
        },
    ];
    readonly APP_LANGUAGES = Object.values(ApplicationLanguage);

    readonly currentLang = mapLanguageStringToApplicationLanguage(LocalStorage.getLang());

    readonly ownedRestaurants$ = combineLatest([this._store.select(selectOwnRestaurants), this._store.select(selectUserRestaurants)]).pipe(
        map(([ownRestaurants, userRestaurants]: [Restaurant[], any[]]) =>
            ownRestaurants.filter((r) => userRestaurants.find((ur) => ur.restaurantId === r._id)?.caslRole === 'owner')
        ),
        tap((restaurants) => {
            this.currentRestaurant = restaurants.find((r) => r._id === this._restaurantsService.currentRestaurant._id);
        })
    );

    readonly candidates$ = combineLatest([
        this._usersService.getUsersForRestaurant(this._restaurantsService.currentRestaurant._id).pipe(map((res) => res.data)),
        this._restaurantsService.currentRestaurant.organization?._id
            ? this._usersService.getOrganizationUsers(this._restaurantsService.currentRestaurant.organization._id)
            : of([]),
    ]).pipe(
        filter(([restaurantUsers, organizationUsers]) => !!restaurantUsers && !!organizationUsers),
        map(([restaurantUsers, organizationUsers]) => organizationUsers.filter((u) => !restaurantUsers.find((ur) => ur.userId === u._id))),
        map((users) =>
            users.map((u) => ({ _id: u._id, email: u.email, language: u.defaultLanguage, getDisplayedValue: (): string => `${u.email}` }))
        )
    );

    readonly allCandidates: WritableSignal<Candidate[]> = signal([]);
    availableCandidates: Signal<Candidate[]>;
    currentSelectedCandidates: Signal<(Candidate | null | undefined)[] | undefined>;
    areExistingUsers = computed(() => this.currentSelectedCandidates()?.map((u) => !!u?._id) ?? []);

    restaurantSelection: Restaurant[] = [];
    displayRestaurantSelection = false;
    currentRestaurant: Restaurant | undefined;
    displayCloseModal = false;

    readonly usersForm: CandidateForm = new FormGroup({
        usersFormArray: new FormArray(
            [
                new FormGroup({
                    user: new FormControl<Candidate | null>(null, Validators.required),
                    caslRole: new FormControl<CaslRole | null>(null, Validators.required),
                    language: new FormControl<ApplicationLanguage | null>(null),
                }),
            ],
            Validators.required
        ),
    });

    readonly isCreatingUser = signal<boolean>(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<NewUserModalComponent>,
        private readonly _fb: FormBuilder,
        private readonly _usersService: UsersService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _toastService: ToastService,
        private readonly _reportsService: ReportsService,
        private readonly _dialogService: DialogService
    ) {
        this.currentSelectedCandidates = toSignal(
            this.usersFormArray.valueChanges.pipe(
                map((value) => value.map((v) => v.user)),
                tap((v) => {
                    const index = v.length - 1;
                    if (v[index]?.language) {
                        this.changeLang(v[index]?.language ?? this.currentLang, index);
                    }
                }),
                distinctUntilChanged(isEqual)
            )
        );
    }

    get usersFormArray(): CandidateFormArray {
        return this.usersForm.controls.usersFormArray;
    }

    ngOnInit(): void {
        this.restaurantSelection.push(this._restaurantsService.currentRestaurant);
        this.candidates$.subscribe((candidates) => {
            this.allCandidates.set(candidates);
        });

        this.availableCandidates = computed(() => {
            const candidates = this.allCandidates().filter(
                (c) => !this.usersFormArray.getRawValue().find((u) => u.user?.email === c.email)
            );
            const currentSelectedCandidates = this.currentSelectedCandidates();
            if (!currentSelectedCandidates?.length) {
                return candidates;
            }
            return candidates.filter((c) => !currentSelectedCandidates.find((u) => u?.email === c.email));
        });
    }

    cancel(updated = false): void {
        this._dialogRef.close(updated);
    }

    buildUser = (email: string): Observable<{ email: string; getDisplayedValue: () => string }> =>
        this._validateEmail$(email).pipe(
            switchMap(() => this._usersService.getUserByEmail(email).pipe(map((res) => res.data))),
            catchError((err) => {
                if (err.status === 404) {
                    return of(null);
                }
                return throwError(() => err);
            }),
            switchMap((user) => {
                if (user) {
                    user.organizationIds = [
                        ...user.organizationIds?.filter((oid) => oid !== this._restaurantsService.currentRestaurant.organizationId),
                        ...(this._restaurantsService.currentRestaurant.organizationId
                            ? [this._restaurantsService.currentRestaurant.organizationId]
                            : []),
                    ];
                    return this._usersService
                        .updateUserOrganizations(user._id, {
                            organizationIds: user.organizationIds,
                        })
                        .pipe(map(() => ({ ...user, getDisplayedValue: () => `${user.email}` })));
                }
                return of({ email, getDisplayedValue: () => email });
            })
        );

    save(): void {
        const shouldWarnBeforeSave =
            this.restaurantSelection?.length <= 1
                ? of(null)
                : this._dialogService
                      .open({
                          title: this._translate.instant('common.oh'),
                          message: this._translate.instant('roles.new_user.will_apply_to_all_restaurants'),
                          variant: DialogVariant.ALERT,
                          primaryButton: {
                              label: this._translate.instant('common.ok'),
                              action: () => {},
                          },
                          secondaryButton: {
                              label: this._translate.instant('common.cancel'),
                              action: () => 'cancel',
                          },
                      })
                      .afterClosed();
        shouldWarnBeforeSave
            .pipe(
                switchMap((userAnswer) => {
                    if (!userAnswer) {
                        this.isCreatingUser.set(true);
                        this.usersForm.disable();
                        const formArrayValue = this.usersFormArray.getRawValue();
                        const usersToCreate = formArrayValue.filter((el) => !el.user?._id);
                        const existingUsers = formArrayValue.filter((el) => !!el.user?._id);

                        const newUsers$ = usersToCreate.length
                            ? forkJoin(
                                  usersToCreate.map((u) =>
                                      this._createNewUser$(u.user!.email!, u.caslRole, u.language!).pipe(
                                          map((user) => ({
                                              caslRole: u.caslRole,
                                              user,
                                              language: u.language,
                                          }))
                                      )
                                  )
                              )
                            : of([]);

                        return newUsers$.pipe(
                            switchMap((newUsers) => {
                                const allUsers = existingUsers.concat(newUsers).filter(isNotNil);
                                return forkJoin(
                                    this.restaurantSelection.flatMap((r) => [
                                        ...allUsers.map((u) => this._updateExistingUser$(r._id, u.user!._id!, u.caslRole!)),
                                    ])
                                );
                            }),
                            map((responses) => responses.map((res) => res.data)),
                            switchMap((userRestaurants) =>
                                this._reportsService.fillUserConfigurations(
                                    userRestaurants[0].userId,
                                    userRestaurants.map((ur) => ur.restaurantId)
                                )
                            )
                        );
                    }
                    return of();
                })
            )
            .subscribe({
                next: () => {
                    this._dialogRef.close();
                    this.isCreatingUser.set(false);
                    this.usersForm.enable();
                },
                error: (err) => {
                    console.warn(err);
                    this.isCreatingUser.set(false);
                    this.usersForm.enable();
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translate).transform(err));
                },
            });
    }

    toggleDisplayRestaurantsSelection(): void {
        this.displayRestaurantSelection = !this.displayRestaurantSelection;
    }

    restaurantsSelectionChanged(restaurant: Restaurant[]): void {
        this.restaurantSelection = restaurant;
    }

    displayUser(user: Partial<User> | string): string {
        return typeof user === 'string' ? user : (user.email ?? '');
    }

    displayCaslRole =
        (key: keyof CaslRoleDescription) =>
        (role: CaslRole): string =>
            this.CASL_ROLES.find((r) => r.key === role)?.[key] || '';

    addUserForm(): void {
        const user = this._fb.group({
            user: new FormControl<Candidate | null>(null, [Validators.required]),
            caslRole: new FormControl<CaslRole | null>(null, [Validators.required]),
            language: new FormControl<ApplicationLanguage | null>(null),
        });
        this.usersFormArray.push(user);
    }

    removeUserForm(index: number): void {
        this.usersFormArray.removeAt(index);
    }

    changeLang(event: ApplicationLanguage | ApplicationLanguage[], index: number): void {
        const lang = Array.isArray(event) ? event[0] : event;
        this.usersFormArray.at(index).get('language')?.setValue(lang, { emitEvent: false });
    }

    close(): void {
        if (this.usersForm.touched) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose();
        }
    }

    confirmClose(): void {
        this._dialogRef.close();
    }

    private _validateEmail$(email: string): Observable<string> {
        const tempForm = new UntypedFormControl(email, Validators.email);
        if (tempForm.valid) {
            return of(email);
        }
        return throwError(() => {
            this._toastService.openErrorToast(this._translate.instant('new_user.error.invalid_email'));
            return new Error(this._translate.instant('new_user.error.invalid_email'));
        });
    }

    private _updateExistingUser$(
        restaurantId: string,
        userId: string,
        caslRole: CaslRole
    ): Observable<ApiResult<AddUserToRestaurantWithEmailResponseDto>> {
        return this._restaurantsService.addRestaurantForUserById(restaurantId, userId, caslRole);
    }

    private _createNewUser$(email: string, caslRole: CaslRole | null, defaultLanguage: ApplicationLanguage): Observable<Candidate> {
        const password = this._generateRandomPassword();
        return this._usersService
            .createUser(
                {
                    email,
                    defaultLanguage: defaultLanguage ?? APP_DEFAULT_LANGUAGE,
                    role: Role.MALOU_BASIC,
                    caslRole: caslRole ?? CaslRole.GUEST,
                    password,
                    organizationIds: this._restaurantsService.currentRestaurant.organization
                        ? [this._restaurantsService.currentRestaurant.organization._id]
                        : [],
                },
                { sendPassword: true }
            )
            .pipe(
                map(({ data: user }) => ({
                    _id: user._id,
                    email: user.email,
                    language: user.defaultLanguage,
                    getDisplayedValue: () => `${user.email}`,
                }))
            );
    }

    private _generateRandomPassword(): string {
        let password = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 8; i++) {
            password += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return password;
    }
}
