import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
    AddUserToRestaurantWithEmailResponseDto,
    AdminUpdateRestaurantBodyDto,
    CreateRestaurantResponseDto,
    GetRestaurantCurrentStateResponseDto,
    RestaurantWithoutStickerDto,
    UpdatePlatformAccessStatusBodyDto,
    UpdateRestaurantOrganizationParamsDto,
    UpdateRestaurantOrganizationRequestBodyDto,
} from '@malou-io/package-dto';
import { ApiResultV2, CaslRole } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { formatArrayKeysForQueryParams } from ':shared/helpers/query-params';
import { Address, ApiResult, Category, Media, PlatformAccess, PostWithInsights, Restaurant, RestaurantInterface } from ':shared/models';

import { IRestaurantAPI, RestaurantsMapper } from './mappers/restaurants.mapper';

@Injectable({ providedIn: 'root' })
export class RestaurantsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/restaurants`;

    restaurantSelected$ = new BehaviorSubject<Restaurant | null>(null);
    currentRestaurant: Restaurant;
    restaurantLoading$ = new BehaviorSubject(false);
    restaurantsLoading$ = new BehaviorSubject(false);
    restaurants = signal<Restaurant[]>([]);

    constructor(private readonly _http: HttpClient) {
        this.restaurantSelected$.subscribe((restaurant: Restaurant) => {
            this.currentRestaurant = new Restaurant(restaurant);
        });
    }

    public addRestaurant(restaurant: Restaurant): void {
        this.restaurants.update((restaurants) => [...restaurants, restaurant]);
    }

    public upsertRestaurant(restaurant: Restaurant): void {
        const index = this.restaurants().findIndex((r) => r._id === restaurant._id);
        if (index !== -1) {
            this.restaurants.update((restaurants) => {
                restaurants[index] = restaurant;
                return [...restaurants];
            });
        } else {
            this.addRestaurant(restaurant);
        }
    }

    public setSelectedRestaurant(restaurant: Restaurant | null): void {
        this.restaurantSelected$.next(restaurant);
    }

    public reloadSelectedRestaurant(): Subscription {
        return this.show((this.restaurantSelected$.value as Restaurant)?._id).subscribe((res) => {
            this.setSelectedRestaurant(res.data);
        });
    }

    public reloadSelectedRestaurant$(): Observable<void> {
        return this.show((this.restaurantSelected$.value as Restaurant)?._id).pipe(map((res) => this.setSelectedRestaurant(res.data)));
    }

    index(fields: string = ''): Observable<Restaurant[]> {
        return this._http.get(this.API_BASE_URL, { params: { fields } }).pipe(
            // ApiResult cannot be typed right now because we use params fields to get only the fields we need
            map((res: ApiResult<any[]>) =>
                res.data.map(
                    (r) =>
                        new Restaurant({
                            ...r,
                            address: new Address(r.address),
                            logo: new Media(r.logo),
                            category: new Category(r.category as any),
                            cover: new Media(r.cover),
                            categoryList: r.categoryList?.map((c) => new Category(c as any)),
                            createdAt: new Date(r.createdAt),
                            updatedAt: new Date(r.updatedAt),
                        })
                )
            ),
            tap((restaurants) => this.restaurants.set(restaurants))
        );
    }

    getUserRestaurantsIds(): Observable<string[]> {
        return this._http
            .get<ApiResultV2<{ _id: string }[]>>(`${this.API_BASE_URL}`, { params: { fields: '_id' } })
            .pipe(map((res) => res.data.map((r) => r._id)));
    }

    all(filters: any = {}, fields: string[] = []): Observable<ApiResult<Restaurant[]>> {
        return this._http.get(this.API_BASE_URL + '/all', { params: { ...filters, fields } }).pipe(
            map((res: ApiResult) => {
                res.data = res.data.map((r: IRestaurantAPI) => RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(r));
                return res;
            })
        );
    }

    show(id: string): Observable<ApiResult<Restaurant>> {
        return this._http.get(`${this.API_BASE_URL}/${id}`).pipe(
            map((res: ApiResult) => {
                res.data = RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(res.data);
                return res;
            })
        );
    }

    create(params: any): Observable<ApiResult<CreateRestaurantResponseDto>> {
        return this._http.post<ApiResult<CreateRestaurantResponseDto>>(this.API_BASE_URL, params, { withCredentials: true });
    }

    update(restaurantId: string, params: any, duplicatedFromRestaurantId: string | null = null): Observable<ApiResult<Restaurant>> {
        const payload = RestaurantsMapper.mapToMalouRestaurantPayload(params);
        return this._http
            .put<ApiResult>(`${this.API_BASE_URL}/${restaurantId}`, { ...payload, duplicatedFromRestaurantId }, { withCredentials: true })
            .pipe(
                map((res: ApiResult) => {
                    res.data = RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(res.data);
                    return res;
                })
            );
    }

    fetchPlatformAndUpsertRestaurant(restaurantId: string, upsertRestaurantPayload: any): Observable<Restaurant> {
        return this._http
            .put<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/upsert_from_platform`, upsertRestaurantPayload)
            .pipe(map((res) => RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(res.data)));
    }

    addRestaurantForUser(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/user/add`);
    }

    addRestaurantForUserById(
        restaurantId: string,
        userId: string,
        caslRole: CaslRole = CaslRole.OWNER
    ): Observable<ApiResult<AddUserToRestaurantWithEmailResponseDto>> {
        return this._http.post<ApiResult<AddUserToRestaurantWithEmailResponseDto>>(
            `${this.API_BASE_URL}/${restaurantId}/users/${userId}/add`,
            { caslRole },
            {
                withCredentials: true,
            }
        );
    }

    updateUserRestaurant(restaurantId: string, usersIds: string[]): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/users/update`, { usersIds }, { withCredentials: true });
    }

    removeRestaurantForUser(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/user/remove`);
    }

    showAccess(restaurantId: string, platformKey): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/platforms/${platformKey}/access`);
    }

    createPlatformAccess(restaurantId: string, platformAccess: PlatformAccess): Observable<ApiResult<Restaurant>> {
        return this._http.post<ApiResult<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/access`, { data: platformAccess }).pipe(
            map((res: ApiResult<Restaurant>) => {
                res.data = new Restaurant(res.data);
                return res;
            })
        );
    }

    updatePlatformAccessStatus(restaurantId: string, payload: UpdatePlatformAccessStatusBodyDto): Observable<void> {
        return this._http.put<void>(`${this.API_BASE_URL}/${restaurantId}/access`, payload);
    }

    delete(id: string): Observable<any> {
        return this._http.delete(`${this.API_BASE_URL}/${id}`);
    }

    synchronize(id: string): Observable<any> {
        return this._http.post(`${this.API_BASE_URL}/${id}/synchronize`, {}).pipe(
            map((res: any) => {
                res.data = new Restaurant(res.data);
                return res;
            })
        );
    }

    publish(restaurantId: string, platformKey: string, restaurantData: RestaurantInterface, validateOnly = true): Observable<any> {
        const data = RestaurantsMapper.mapToRestaurantApiPayload(restaurantData);
        return this._http.post(
            `${this.API_BASE_URL}/${restaurantId}/platforms/${platformKey}/publish`,
            { data, validateOnly },
            { withCredentials: true }
        );
    }

    bookmarkedPostJob(restaurantId: string, post: PostWithInsights): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/jobs/bookmarked_post`, { post });
    }

    getRestaurantCurrentState(restaurantId: string): Observable<ApiResult<GetRestaurantCurrentStateResponseDto>> {
        return this._http.get<ApiResult<GetRestaurantCurrentStateResponseDto>>(`${this.API_BASE_URL}/${restaurantId}/currentState`);
    }

    updateActive(restaurantId: string, active: boolean): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/admin/active`, { active })
            .pipe(map((res) => new Restaurant(res.data)));
    }

    adminUpdate(restaurantId: string, data: AdminUpdateRestaurantBodyDto): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/admin`, data)
            .pipe(map((res) => new Restaurant(res.data)));
    }

    activateYextForRestaurant(restaurantId: string): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/yext/activate`, {})
            .pipe(map((res) => new Restaurant(res.data)));
    }

    deactivateYextForRestaurant(restaurantId: string): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/yext/deactivate`, {})
            .pipe(map((res) => new Restaurant(res.data)));
    }

    updateRestaurantOrganization(
        params: UpdateRestaurantOrganizationParamsDto,
        body: UpdateRestaurantOrganizationRequestBodyDto
    ): Observable<ApiResultV2<undefined>> {
        return this._http.put<ApiResultV2<undefined>>(`${this.API_BASE_URL}/${params.restaurantId}/organization`, body);
    }

    getRestaurantsByIds(restaurantIds: string[]): Observable<Restaurant[]> {
        const params = formatArrayKeysForQueryParams({ restaurantIds });
        return this._http
            .get<ApiResultV2<Restaurant[]>>(`${this.API_BASE_URL}/ids`, { params })
            .pipe(map((res) => res.data.map((r) => new Restaurant(r))));
    }

    getRestaurantsWithoutSticker(): Observable<Restaurant[]> {
        return this._http
            .get<ApiResultV2<RestaurantWithoutStickerDto[]>>(`${this.API_BASE_URL}/without-sticker`)
            .pipe(map((res) => res.data.map((r) => new Restaurant({ ...r, address: new Address(r.address) }))));
    }
}
